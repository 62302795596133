<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <!-- <el-col :span="4">
        <el-select
          v-model="query.stationId"
          placeholder="站点"
          filterable
          clearable
        >
         
          <template v-for="(item, index) in stationList.list">
            <el-option
              v-if="item.stationMacId != ''"
              :label="item.stationName"
              :value="item.id"
            ></el-option>
          </template>
        </el-select>
      </el-col> -->

      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="doExportAmmeters"
          >导出电量数据</el-button
        >
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="站点编号" prop="stationName"></el-table-column>
      <el-table-column label="站点编号" prop="stationMacId"></el-table-column>
      <el-table-column label="开始时间" prop="startTime"></el-table-column>
      <el-table-column label="开始电量" prop="startPower"></el-table-column>
      <el-table-column label="结束时间" prop="endTime"></el-table-column>
      <el-table-column label="结束电量" prop="endPower"></el-table-column>
      <el-table-column label="耗电量" prop="usePower"></el-table-column>
    </el-table>
  </div>
</template>
 
<script>

import { fetchAmmetersCount, exportAmmetersCount } from "@/api/station";
import { mapState } from "vuex";
import moment from "moment";
export default {
  computed: mapState({
    stationList: (state) => {
      return state.station.stationList || { list: [], map: {} };
    },
  }),

  data() {
    let startTime = moment()
      .subtract(1, "month")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");

    return {
      moment: moment,
      dateRange: [startTime, endTime],
      dataSource: [],

      query: {
        startTime,
        endTime
      },
      total: 0,
    };
  },
  methods: {
    doExportAmmeters() {
      exportAmmetersCount({
        startTime: this.query.startTime,
        endTime: this.query.endTime,
      }).then((res) => {
         this.util.apiDownLoadFile(res.data,"application/vnd.ms-excel",`${this.query.startTime}-${this.query.endTime}站点电量.xlsx`)
      });
    },
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        this.dateRange = [this.query.startTime, this.query.endTime];
      }
    },
    getList(current) {
     
      fetchAmmetersCount({...this.query}).then(
        (res) => {
          let data = res.data.data || {}; 
          this.dataSource = Object.values(data) || [];
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch("fetchStationAll");
    this.getList();
  },
};
</script>
